<template>
  <v-container id="youtube-management" fluid>
    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />

    <!-- <v-row
      no-gutters
      class="mb-5"
    >
      <v-col
        cols="12"
        sm="4"
        class="pl-1"
      >
        <v-text-field
          hide-details
          :placeholder="$t('base.search')"
          filled
          rounded
          dense
          v-model="searchTxt"
          prepend-inner-icon="mdi-magnify"
        >
          <template v-slot:append-outer>
            <v-btn
              style="margin-top:-10px;"
              color="pink"
              fab
              dark
              small
              @click="onOpenNewForm"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-text-field>

      </v-col>

    </v-row> -->

    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :title="$t('nav.youtubeLink')"
      :headers="fillableHeaders"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <!-- <v-simple-table
      v-if="!loading"
      fixed-header
    >

      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(tblHeader,tblIndex) in fillableHeaders"
              :key="`tblHeader-${tblIndex}`"
            >
              {{ $t(tblHeader.label) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in filteredList"
            :key="`edr-youtube-mapper-${index}`"
          >
            <td>
              <v-img
                v-if="item.metadata && item.metadata.image"
                width="125"
                :src="item.metadata.image"
              ></v-img>
              <v-img
                v-else
                :key="`item-edr-link-${item.id}`"
                width="125"
                src="https://cdn.appedr.com/img/no_picture_thumb.jpg"
              ></v-img>
            </td>
            <td>{{item.description}}</td>
            <td>
              <v-icon :color="item.active ? 'green accent-4' : 'grey'">mdi-check-circle</v-icon>
            </td>
            <td><a
                :href="item.youtube_link"
                target="_blank"
              >{{item.youtube_link}}</a></td>
            <td>
              <span>{{item.edrList.length>1 ? item.edrList.length+ ' Links' : item.edrList.length+ ' Link'}}</span>
            </td>
            <td>
              <v-btn
                icon
                color="primary"
                @click="onClickItem(index)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="pink"
                @click="onBeforeDeleteItem(index)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- End Table  -->

    <!-- Paging -->
    <!-- <v-row
      justify="start"
      align="center"
    >
      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          :items="pages.perPageList"
          v-model="pages.itemsPerPage"
          :label="$t('paging.rowsPerPage')"
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-pagination
          v-model="pages.current"
          :length="pages.totalPage"
          :total-visible="7"
          :circle="false"
        ></v-pagination>
      </v-col>
    </v-row> -->
    <!-- End Paging  -->

    <!--      Start  Form -->
    <v-dialog
      v-model="openNewForm"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
            icon
            dark
            :loading="isProcessing"
            :disabled="isProcessing"
            @click="openNewForm = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-btn
            text
            color="primary"
            :loading="isProcessing"
            :disabled="isProcessing"
            @click="openNewForm = false"
          >{{$t('base.cancel') }}
          </v-btn> -->
          <v-toolbar-title>Create/Update Link</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="onSave" :disabled="isProcessing"
              >{{ $t("base.okay") }}
            </v-btn>
            <!-- <v-btn
              dark
              text
              @click="openNewForm = false"
            >Save</v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <!-- <v-card-title>
          Create Link
        </v-card-title> -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-pencil"
                  v-model="entity.description"
                  placeholder="Description"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  prepend-icon="mdi-youtube"
                  v-model="entity.youtube_link"
                  placeholder="Youtube link"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="entity.active"
                  :label="entity.active ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-col>

              <v-subheader class="mt-4">Edr Link</v-subheader>
              <v-col cols="12">
                <v-text-field
                  v-model="edrLinkTxt"
                  prepend-icon="mdi-pencil"
                  append-outer-icon="mdi-plus"
                  clear-icon="mdi-close-circle"
                  clearable
                  type="text"
                  placeholder="Edr link"
                  @click:append-outer="onAddEdrLinkItem"
                  @click:clear="edrLinkTxt = ''"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-list>
                  <v-list-item
                    v-for="(edrLink, i) in entity.edrList"
                    :key="`edr-link-${i}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        edrLink.edr_link
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon @click="onDeleteEdrLink(i)">
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :loading="isProcessing"
            :disabled="isProcessing"
            @click="openNewForm = false"
          >{{$t('base.cancel') }}
          </v-btn>
          <v-btn
            text
            @click="onSave"
            :disabled="isProcessing"
          >{{$t('base.okay') }}
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
  </v-container>
</template>

<script>
// service
import EdrYoutubeMapperService from "@/api/EdrYoutubeMapperService";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onBeforeUnmount, ref } from "@vue/composition-api";
export default {
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable")
  },
  setup(props, { refs, root }) {
    const service = new EdrYoutubeMapperService();
    const tableHeaders = [
      {
        column: "metadata.image",
        label: "base.img",
        sortable: false,
        fillable: true,
        image: true
      },
      {
        column: "id",
        label: "Id",
        sortable: true,
        fillable: false
      },
      {
        column: "description",
        label: "model.edr_youtube_mapper.description",
        sortable: true,
        fillable: true,
        searchable: true
      },
      {
        column: "active",
        label: "model.edr_youtube_mapper.actiive",
        sortable: true,
        fillable: true,
        status: true
      },
      {
        column: "youtube_link",
        label: "model.edr_youtube_mapper.youtube_link",
        sortable: true,
        fillable: true,
        linkable: { external: true }
      },
      {
        // column: "edrList",
        label: "model.edr_youtube_mapper.edr_link",
        sortable: false,
        fillable: true,
        countable: {
          text: true
        }
      },
      {
        label: "base.tool",
        sortable: false,
        fillable: true,
        baseTool: true
      }
    ];
    // entity
    const initialItem = {
      id: null,
      metadata: "",
      description: "",
      active: false,
      youtube_link: "",
      edrList: []
    };
    const edrLinkTxt = ref("");
    const { crud } = useCrudApi(refs, root, service, initialItem, tableHeaders);

    // fell free to change sort colunm and mode
    // sort.column = "id";
    // sort.mode = "ASC";
    // edr link
    const onAddEdrLinkItem = () => {
      if (edrLinkTxt.value.length > 0) {
        crud.entity.edrList.push({
          id: null,
          edr_youtube_mapper: null,
          edr_link: edrLinkTxt.value
        });
        edrLinkTxt.value = "";
      }
    };
    const onDeleteEdrLink = index => {
      crud.entity.edrList.splice(index, 1);
    };

    onBeforeUnmount(() => {});

    return {
      ...toRefs(crud),
      // edrlink
      edrLinkTxt,
      onAddEdrLinkItem,
      onDeleteEdrLink
    };
  }
};
</script>

<style></style>