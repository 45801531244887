import Service from './Service';

class EdrYoutubeMapperService extends Service{
    constructor(){
        super();
    }
    async get(pageParam){
        return this.callApiGet(`/edrYoutubeMapper${pageParam}`);
    }
    async create(postData){
        return this.callApiPost(`/edrYoutubeMapper`, postData);
    }
    async update(postData){
        return this.callApiPut(`/edrYoutubeMapper`, postData);
    }
    async delete(id){
        return this.callApiDelete(`/edrYoutubeMapper?_ids=${id}`);
    }
}
export default EdrYoutubeMapperService